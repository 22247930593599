<script setup lang="ts">
  import {useI18n} from 'vue-i18n';
  import type {ILastSeenWidget, IProductHistoryType} from '~/utils/services/api';
  import {useProductHistory} from '~/composables/queries/product-history';
  import {useLastSeen} from '~/composables';
  import {useCpUser} from '~/composables/useCpUser';

  const props = defineProps<{
    widget: ILastSeenWidget
  }>();

  const {t} = useI18n();
  const articles = ref<any>([]);
  const loadArticles = async () => {
    try {
      if (useCpUser().user.value) {
        const {data: productHistory} = await useProductHistory();
        if (productHistory.value) {
          return productHistory.value?.data.map((a: IProductHistoryType) => {
            return a.article;
          });
        }
      } else {
        const {data: productHistoryUnAuth} = await useLastSeen();
        if (productHistoryUnAuth.value && productHistoryUnAuth.value.length) {
          const ids = productHistoryUnAuth.value?.map((a) => a.id);
          const {data} = await useAFCatalogArticles(ids, false, props.widget.type);
          if (data && data.value && data.value.articles) {
            return data.value.articles;
          }
        }
      }
    } catch {}

    return [];
  };

  articles.value = await loadArticles();

</script>

<template>
    <div>
        <CpSectionHomeViewHistory
            v-if="articles.length"
            :title="widget.title"
            :items-to-show="articles"
            :button-label="t('see-history')"
        />
    </div>
</template>

<i18n locale="es-US">
  see-history: "Ver todo el historial"
</i18n>
